import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16')
];

export const server_loads = [0];

export const dictionary = {
		"/": [4],
		"/errors/host": [5,[2]],
		"/errors/no-code": [6,[2]],
		"/errors/office": [7,[2]],
		"/legal/imprint": [8],
		"/legal/privacy": [9],
		"/legal/terms": [10],
		"/steps/1": [~11,[3]],
		"/steps/2": [~12,[3]],
		"/steps/2/office": [~13,[3]],
		"/steps/3": [~14,[3]],
		"/steps/4": [~15,[3]],
		"/steps/5": [~16,[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';